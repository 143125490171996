import React, {ErrorInfo} from 'react';
import {withRouter} from 'react-router-dom';

class ErrorBoundary extends React.Component<any, { error: Error|null }> {

  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return {
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorName = error.name;
    if (errorName === 'ChunkLoadError') {
      // @ts-ignore
      window.location = '/';
    }
  }

  render() {
    if (this.state.error) {
      const message = this.state.error.message;
      const stack = this.state.error.stack;
      return (
        <div>
          <div>Произошла ошибка!</div>
          <div>{message}</div>
          {stack?.split('\n').map((item: any, index: number) =>
            <div key={index}>{item}</div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
