import {Wrapper, Inner, Content, Media, MediaInner, MediaContent, MediaPicWrapper, MediaPic, Details, DetailsInner, Title, Description} from './controls';
import Container from '../../../../components/container';
import {Picture} from 'react-responsive-picture';

import theme from '../../../../theme';

import teamsMobile from '../../../../media/images/teams_mobile.png';
import teamsMobile_2x from '../../../../media/images/teams_mobile@2x.png';
import teamsTabletPortrait from '../../../../media/images/teams_tabletPortrait.png';
import teamsTabletPortrait_2x from '../../../../media/images/teams_tabletPortrait@2x.png';
import teamsTabletLandscape from '../../../../media/images/teams_tabletLandscape.png';
import teamsTabletLandscape_2x from '../../../../media/images/teams_tabletLandscape@2x.png';
import teams from '../../../../media/images/teams.png';
import teams_2x from '../../../../media/images/teams@2x.png';

const LandingTeams = () => {
  return (
    <Wrapper>
      <Inner>
        <Container>
          <Content>
            <Media>
              <MediaInner>
                <MediaContent>
                  <MediaPicWrapper>
                    <MediaPic>
                      <Picture
                        sources={[
                          {
                            srcSet: `${teamsMobile}, ${teamsMobile_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.sm})`,
                          },
                          {
                            srcSet: `${teamsTabletPortrait}, ${teamsTabletPortrait_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.md})`,
                          },
                          {
                            srcSet: `${teamsTabletLandscape}, ${teamsTabletLandscape_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.lg})`,
                          },
                          {
                            srcSet: `${teams}, ${teams_2x} 2x`,
                          },
                        ]}
                      />
                    </MediaPic>
                  </MediaPicWrapper>
                </MediaContent>
              </MediaInner>
            </Media>
            <Details>
              <DetailsInner>
                <Title>Команды</Title>
                <Description>Объединяйся с&nbsp;друзьями и&nbsp;находи новых по&nbsp;интересам в&nbsp;нашем приложении</Description>
              </DetailsInner>
            </Details>
          </Content>
        </Container>
      </Inner>
    </Wrapper>
  );
};

export default LandingTeams;