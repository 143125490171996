import {useMediaQuery} from 'beautiful-react-hooks';
import {Picture} from 'react-responsive-picture';
import {EAppPlatformType} from '../../../../../domain/model/enums';
import Container from '../../../../components/container';
import Link from '../../../../components/links';

import Logo from '../../../../components/logo';

import introDecor from '../../../../media/images/introDecor.png';
import introDecor_2x from '../../../../media/images/introDecor@2x.png';
import introDecorMobile from '../../../../media/images/introDecor_mobile.png';
import introDecorMobile_2x from '../../../../media/images/introDecor_mobile@2x.png';
import introDecorTabletLandscape from '../../../../media/images/introDecor_tabletLandscape.png';
import introDecorTabletLandscape_2x from '../../../../media/images/introDecor_tabletLandscape@2x.png';
import introDecorTabletPortrait from '../../../../media/images/introDecor_tabletPortrait.png';
import introDecorTabletPortrait_2x from '../../../../media/images/introDecor_tabletPortrait@2x.png';

import introMobile from '../../../../media/images/introMobile.png';
import introMobile_2x from '../../../../media/images/introMobile@2x.png';
import introMobileMobile from '../../../../media/images/introMobile_mobile.png';
import introMobileMobile_2x from '../../../../media/images/introMobile_mobile@2x.png';
import introMobileTabletLandscape from '../../../../media/images/introMobile_tabletLandscape.png';
import introMobileTabletLandscape_2x from '../../../../media/images/introMobile_tabletLandscape@2x.png';
import introMobileTabletPortrait from '../../../../media/images/introMobile_tabletPortrait.png';
import introMobileTabletPortrait_2x from '../../../../media/images/introMobile_tabletPortrait@2x.png';
import theme from '../../../../theme';
import {DownloadLinkType} from '../../types';
import {isSafari} from '../../utils';
import DownloadButtons from '../downloadButtons';
import {
  Content,
  Details,
  DetailsFooter,
  Inner,
  LogoWrapper,
  Media,
  MobilePic,
  MobilePicDecor,
  MobilePicGroup,
  Pattern,
  Pic,
  PicDecor,
  PicGroup,
  PseudoLink,
  Text,
  Title,
  Wrapper
} from './controls';

interface LandingHeaderProps {
  readonly faqVisible: boolean
  readonly requestedPlatform: EAppPlatformType | null
  readonly downloadLinks: DownloadLinkType[]
  readonly onOpenAppleModal: () => void
  readonly onOpenAndroidModal: () => void
  readonly onOpenHuaweiModal: () => void
}

const LandingHeader = (props: LandingHeaderProps) => {
  const {
    faqVisible,
    requestedPlatform,
    downloadLinks,
    onOpenAppleModal,
    onOpenAndroidModal,
    onOpenHuaweiModal
  } = props;

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Wrapper>
      {!isSafari && <Pattern/>}
      <Inner>
        <Container>
          <Content>
            <Details>
              <LogoWrapper>
                <Logo/>
              </LogoWrapper>
              <Title>Культурные и&nbsp;спортивные мероприятия поблизости</Title>
              {isMobile && (
                <MobilePicGroup>
                  <MobilePicDecor src={introDecorMobile} srcSet={`${introDecorMobile_2x} 2x`} alt=""/>
                  <MobilePic src={introMobileMobile} srcSet={`${introMobileMobile_2x} 2x`} alt=""/>
                </MobilePicGroup>
              )}
              <Text>Создавай команды, находи единомышленников, узнавай о&nbsp;событиях первым</Text>
              <DetailsFooter>
                <DownloadButtons
                  isHeaderPosition
                  requestedPlatform={requestedPlatform}
                  downloadLinks={downloadLinks}
                  onOpenAppleModal={onOpenAppleModal}
                  onOpenAndroidModal={onOpenAndroidModal}
                  onOpenHuaweiModal={onOpenHuaweiModal}
                />
                {faqVisible && (
                  <Link color="light">
                    <PseudoLink
                      to="accordion"
                      smooth={true}
                      duration={800}
                      offset={-50}
                    >
                      Как установить приложение?
                    </PseudoLink>
                  </Link>
                )}
              </DetailsFooter>
            </Details>
            {!isMobile && (
              <Media>
                <PicGroup>
                  <PicDecor>
                    <Picture
                      sources={[
                        {
                          srcSet: `${introDecorTabletPortrait}, ${introDecorTabletPortrait_2x} 2x`,
                          media: `(max-width: ${theme.breakpoints.md})`,
                        },
                        {
                          srcSet: `${introDecorTabletLandscape}, ${introDecorTabletLandscape_2x} 2x`,
                          media: `(max-width: ${theme.breakpoints.lg})`,
                        },
                        {
                          srcSet: `${introDecor}, ${introDecor_2x} 2x`,
                        },
                      ]}
                    />
                  </PicDecor>
                  <Pic>
                    <Picture
                      sources={[
                        {
                          srcSet: `${introMobileTabletPortrait}, ${introMobileTabletPortrait_2x} 2x`,
                          media: `(max-width: ${theme.breakpoints.md})`,
                        },
                        {
                          srcSet: `${introMobileTabletLandscape}, ${introMobileTabletLandscape_2x} 2x`,
                          media: `(max-width: ${theme.breakpoints.lg})`,
                        },
                        {
                          srcSet: `${introMobile}, ${introMobile_2x} 2x`,
                        },
                      ]}
                    />
                  </Pic>
                </PicGroup>
              </Media>
            )}
          </Content>
        </Container>
      </Inner>
    </Wrapper>
  );
};

export default LandingHeader;
