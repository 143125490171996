import {Wrapper, Inner, PicDecor, Content, Media, MediaInner, MediaContent, MediaPicWrapper, MediaPic, Details, DetailsInner, Title, Description} from './controls';
import {Picture} from 'react-responsive-picture';
import Container from '../../../../components/container';

import ball from '../../../../media/images/ball.png';
import ball_2x from '../../../../media/images/ball@2x.png';
import ballTablet from '../../../../media/images/ball_tablet.png';
import ballTablet_2x from '../../../../media/images/ball_tablet@2x.png';
import theme from '../../../../theme';
import {useMediaQuery} from 'beautiful-react-hooks';

import shopMobile from '../../../../media/images/shop_mobile.png';
import shopMobile_2x from '../../../../media/images/shop_mobile@2x.png';
import shopTabletPortrait from '../../../../media/images/shop_tabletPortrait.png';
import shopTabletPortrait_2x from '../../../../media/images/shop_tabletPortrait@2x.png';
import shopTabletLandscape from '../../../../media/images/shop_tabletLandscape.png';
import shopTabletLandscape_2x from '../../../../media/images/shop_tabletLandscape@2x.png';
import shop from '../../../../media/images/shop.png';
import shop_2x from '../../../../media/images/shop@2x.png';

const LandingShop = () => {
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Wrapper>
      <Inner>
        {!isMobile && (
          <PicDecor>
            <Picture
              sources={[
                {
                  srcSet: `${ballTablet}, ${ballTablet_2x} 2x`,
                  media: `(max-width: ${theme.breakpoints.lg})`,
                },
                {
                  srcSet: `${ball}, ${ball_2x} 2x`,
                },
              ]}
            />
          </PicDecor>
        )}
        <Container>
          <Content>
            <Media>
              <MediaInner>
                <MediaContent>
                  <MediaPicWrapper>
                    <MediaPic>
                      <Picture
                        sources={[
                          {
                            srcSet: `${shopMobile}, ${shopMobile_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.sm})`,
                          },
                          {
                            srcSet: `${shopTabletPortrait}, ${shopTabletPortrait_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.md})`,
                          },
                          {
                            srcSet: `${shopTabletLandscape}, ${shopTabletLandscape_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.lg})`,
                          },
                          {
                            srcSet: `${shop}, ${shop_2x} 2x`,
                          },
                        ]}
                      />
                    </MediaPic>
                  </MediaPicWrapper>
                </MediaContent>
              </MediaInner>
            </Media>
            <Details>
              <DetailsInner>
                <Title>Витрина</Title>
                <Description>Зарабатывай бонусы и трать их на витрине предложений</Description>
              </DetailsInner>
            </Details>
          </Content>
        </Container>
      </Inner>
    </Wrapper>
  );
};

export default LandingShop;