import {Spinner, Wrapper} from './controls';

const ContentLoader = () => {
  return (
    <Wrapper>
      <Spinner/>
    </Wrapper>
  );
};

export default ContentLoader;
