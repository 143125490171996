import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  > * {
    margin-bottom: 8px;
  }
`;
