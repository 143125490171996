import styled from '@emotion/styled';
import theme from '../../theme';

type StyledButtonProps = {
  fullWidth: boolean
}

export const StyledButton = styled.button<StyledButtonProps>`
  min-height: 56px;

  ${p => p.fullWidth && `
    width: 100%;
  `};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-family: inherit;
  font-size: ${theme.typography.textSmall.fontSize};
  line-height: ${theme.typography.textSmall.lineHeight};
  color: ${theme.palette.text.light};

  background-color: ${theme.palette.primary.main};
  border: 0;
  margin: 0;
  padding: 0 32px;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  text-decoration: none;
  transition: .2s ease-in-out;

  &:hover {
    background-color: ${theme.palette.primary.light};
  }

  &:active {
    background-color: ${theme.palette.primary.dark};
    transition: none;
  }

  & > svg {
    margin-right: 10px;
    fill: ${theme.palette.text.light};
  }

  ${p => p.disabled && `
   background-color: ${theme.palette.background.disabled} !important;
   color: ${theme.palette.text.disabled} !important;
   cursor: not-allowed; 
   
     & > svg {
      margin-right: 10px;
      fill: ${theme.palette.text.disabled} !important;
    }
  `};
`;