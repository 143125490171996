import styled from '@emotion/styled';
import theme from '../../../../theme';

export const Content = styled.div`
  text-align: center;
`;

export const Title = styled.h2`
  font-size: ${theme.typography.h2.fontSize};
  line-height: ${theme.typography.h2.lineHeight};

  margin-bottom: 8px;
`;

export const Text = styled.div`
  font-size: ${theme.typography.text.fontSize};
  line-height: ${theme.typography.text.lineHeight};

  margin-top: 16px;
`;

export const QRCodeWrapper = styled.div`
  padding: 22px;
  margin-bottom: 24px;

  & > svg {
    vertical-align: top;
  }
`;

export const ButtonWrapper = styled.div`
`;

export const Caption = styled.p`
  font-size: ${theme.typography.textSmall.fontSize};
  line-height: ${theme.typography.textSmall.lineHeight};

  margin-bottom: 12px;
`;
