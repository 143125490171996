import QRCode from 'react-qr-code';
import Modal from '../../../../components/modal';
import {QrCodeSize} from '../../../../utils/constants';
import {Content, QRCodeWrapper, Text, Title} from './controls';

interface AppleModalProps {
  readonly url: string
  readonly canDownload?: boolean
  readonly onClose: () => void
}

const AppleModal = ({url, canDownload, onClose}: AppleModalProps) => {
  return (
    <Modal onClose={onClose}>
      {canDownload && (
        <Content>
          <Title>Считайте камерой телефона QR код для скачивания</Title>
          <QRCodeWrapper>
            <QRCode value={url} size={QrCodeSize}/>
          </QRCodeWrapper>
        </Content>
      )}
      {!canDownload && (
        <Content>
          <Title>Приложение «Пульс» временно недоступно на iOS</Title>
          <Text>
            Сейчас мы работаем над обновлением приложения «Пульс» с новыми возможностями. Следите за новостями, мы
            обязательно сообщим когда оно будет готово.
          </Text>
          <Text>
            Спасибо за терпение и понимание.
          </Text>
        </Content>
      )}
    </Modal>
  );
};

export default AppleModal;
