import React, {FC} from 'react';
import {Element} from 'react-scroll';
import {EAppPlatformType} from '../../../../../domain/model/enums';
import Accordion from '../../../../components/accordion';
import Container from '../../../../components/container';
import Logo from '../../../../components/logo';
import ImageStepFive from '../../../../media/images/iosSteps/ios_step_five.png';
import ImageStepFour from '../../../../media/images/iosSteps/ios_step_four.png';
import ImageStepOne from '../../../../media/images/iosSteps/ios_step_one.png';
import ImageStepSeven from '../../../../media/images/iosSteps/ios_step_seven.png';
import ImageStepSix from '../../../../media/images/iosSteps/ios_step_six.png';
import ImageStepThree from '../../../../media/images/iosSteps/ios_step_three.png';
import {DownloadLinkType} from '../../types';
import {isSafari} from '../../utils';
import DownloadButtons from '../downloadButtons';
import {FooterQR} from './components/footerQR/footerQR';
import {
  AccordionList,
  AccordionListItem,
  AccordionWrapper,
  Content,
  Header,
  HelpWrapper,
  LogoWrapper,
  Pattern,
  Wrapper,
} from './controls';

interface LandingFooterProps {
  readonly help?: React.ReactNode
  readonly requestedPlatform: EAppPlatformType | null
  readonly downloadLinks: DownloadLinkType[]
  readonly iOSqrCodeUrl: string;
  readonly onOpenAppleModal: () => void
  readonly onOpenAndroidModal: () => void
  readonly onOpenHuaweiModal: () => void
}

const LandingFooter = (props: LandingFooterProps) => {
  const {
    help,
    requestedPlatform,
    downloadLinks,
    onOpenAppleModal,
    iOSqrCodeUrl,
    onOpenAndroidModal,
    onOpenHuaweiModal
  } = props;

  const iosDownloadLink = downloadLinks.find(dl => dl.platformType === EAppPlatformType.Ios);

  const accordionData = [
    {
      title: 'Как установить приложение?',
      content: () => (
        <AccordionList>
          <AccordionListItem>Нажмите на кнопку с видом вашей операционной системы, после чего отобразится
            QR-код.</AccordionListItem>
          <AccordionListItem>Наведите камеру вашего смартфона на QR-код.</AccordionListItem>
          <AccordionListItem>Перейдите по открывшейся ссылке.</AccordionListItem>
          <AccordionListItem>Или нажмите на кнопку «Скачать приложение» и загрузите файл на ваш
            смартфон.</AccordionListItem>
          <AccordionListItem>Откройте загруженный файл и запустите установку приложения.</AccordionListItem>
        </AccordionList>
      )
    },
    iosDownloadLink?.canDownload ? {
      title: 'Как разрешить установку приложения на iOS?',
      content: () => (
        <AccordionList>
          <AccordionListItem>
            <div>Выберите из списка устройств Iphone и нажмите на кнопку.<img src={ImageStepOne} alt=""/></div>
          </AccordionListItem>
          <AccordionListItem>
            <div>В появившемся окне отсканируйте появившийся QR-код.</div>
            <FooterQR iOSqrCodeUrl={iOSqrCodeUrl}/>
          </AccordionListItem>
          <AccordionListItem>
            <div>При загрузке файла отобразится окно «my.rzd.ru собирается установить приложение «Пульс», выберите пункт
              «Установить».<img src={ImageStepThree} alt=""/></div>
          </AccordionListItem>
          <AccordionListItem>
            <div>Перейдите в системные настройки смартфона.<img src={ImageStepFour} alt=""/></div>
          </AccordionListItem>
          <AccordionListItem>
            <div>Выберите раздел «Основные», далее «VPN и управление устройством» или «Управление устройством».<img
              src={ImageStepFive} alt=""/></div>
          </AccordionListItem>
          <AccordionListItem>
            <div>Выберите программу «RZHD, PAO».<img src={ImageStepSix} alt=""/></div>
          </AccordionListItem>
          <AccordionListItem>
            <div>На странице приложения нажмите кнопку «Доверять «RZHD, PAO». Во всплывающем окне выберите «Доверять
              программам от «RZHD, PAO» на этом Iphone» и нажмите кнопку «Доверять».<img src={ImageStepSeven} alt=""/>
            </div>
          </AccordionListItem>
          <AccordionListItem>Готово, теперь вы можете пользоваться приложением!</AccordionListItem>
        </AccordionList>
      )
    } : undefined,
    {
      title: 'Как зарегистрироваться?',
      content: () => (
        <AccordionList>
          <AccordionListItem>Для регистрации в приложении сотрудникам РЖД необходимо в авторизационном окне нажать
            кнопку «Войти через Сервисный портал работника ОАО «РЖД»».</AccordionListItem>
          <AccordionListItem>Введите логин и пароль сервисного портала.</AccordionListItem>
          <AccordionListItem>Произойдет переход на форму регистрации в приложении «Пульс». Задайте логин (email) и
            установите новый пароль для входа в приложение.</AccordionListItem>
        </AccordionList>
      )
    },
  ].filter(item => !!item) as {
    readonly title: string
    readonly content: FC
  }[];

  return (
    <Wrapper>
      {!isSafari && <Pattern/>}
      <Content>
        <Container>
          <Header>
            <LogoWrapper>
              <Logo/>
            </LogoWrapper>
            <DownloadButtons
              requestedPlatform={requestedPlatform}
              downloadLinks={downloadLinks}
              onOpenAppleModal={onOpenAppleModal}
              onOpenAndroidModal={onOpenAndroidModal}
              onOpenHuaweiModal={onOpenHuaweiModal}
            />
          </Header>
          <Element name="accordion">
            <AccordionWrapper>
              <Accordion data={accordionData}/>
            </AccordionWrapper>
          </Element>
          {help && <HelpWrapper>{help}</HelpWrapper>}
        </Container>
      </Content>
    </Wrapper>
  );
};

export default LandingFooter;
