import styled from '@emotion/styled';
import {Link} from 'react-scroll';
import pattern from '../../../../media/pattern.svg';
import theme from '../../../../theme';

export const Wrapper = styled.div`
  position: relative;

  background: linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.light} 100%);
  color: ${theme.palette.text.light};
`;

export const Inner = styled.div`
  position: relative;
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Details = styled.div`
  flex: 0 0 464px;
  max-width: 464px;

  padding-top: 174px;
  padding-bottom: 174px;

  @media (max-width: ${theme.breakpoints.lg}) {
    padding-top: 140px;
    padding-bottom: 150px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    flex: 0 0 394px;
    max-width: 394px;

    padding-top: 120px;
    padding-bottom: 120px;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    padding-top: 64px;
    padding-bottom: 64px;

    flex: 0 0 100%;
    max-width: 100%;

    text-align: center;
  }
`;

export const LogoWrapper = styled.div`
  margin-bottom: 16px;

  @media (max-width: ${theme.breakpoints.lg}) {
    margin-bottom: 24px;
  }
`;

export const Title = styled.h1`
  font-size: ${theme.typography.h1.fontSize};
  line-height: ${theme.typography.h1.lineHeight};

  margin-bottom: 8px;

  @media (max-width: ${theme.breakpoints.lg}) {
    font-size: ${theme.typography.h2.fontSize};
    line-height: ${theme.typography.h2.lineHeight};
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.typography.subtitle.fontSize};
    line-height: ${theme.typography.subtitle.lineHeight};

    max-width: 286px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Text = styled.p`
  max-width: 400px;

  @media (max-width: ${theme.breakpoints.lg}) {
    max-width: 360px;

    font-size: ${theme.typography.textSmall.fontSize};
    line-height: ${theme.typography.textSmall.lineHeight};
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    max-width: 286px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const DetailsFooter = styled.footer`
  margin-top: 48px;

  @media (max-width: ${theme.breakpoints.lg}) {
    margin-top: 24px;
  }
`;

export const PseudoLink = styled(Link)`
  font-size: ${theme.typography.textSmall.fontSize};
  line-height: ${theme.typography.textSmall.lineHeight};
`;

export const Media = styled.div`
  position: relative;

  flex-grow: 1;
`;

export const PicGroup = styled.div`
  width: 276px;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -120px;

  pointer-events: none;

  @media (max-width: ${theme.breakpoints.lg}) {
    width: 218px;

    bottom: -92px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    width: 186px;

    bottom: 0;
  }
`;

export const Pic = styled.div`
  position: relative;
  z-index: 1;

  img {
    width: 100%;
  }
`;

export const PicDecor = styled.div`
  position: absolute;
  left: -200px;
  top: -50px;

  width: 746px;

  img {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.lg}) {
    width: 509px;

    left: -130px;
    top: auto;
    bottom: 0;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    width: 383px;

    left: -90px;
    top: -25px;
    bottom: auto;
  }
`;

export const MobilePicGroup = styled.div`
  width: 160px;

  margin: 40px auto 20px;

  position: relative;

  pointer-events: none;
`;

export const MobilePic = styled.img`
  width: 100%;

  position: relative;
  z-index: 1;
`;

export const MobilePicDecor = styled.img`
  width: 286px;

  position: absolute;
  top: -20px;
  left: -62px;
`;

export const Pattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;

  &:before {
    content: '';
    opacity: .05;

    position: absolute;
    top: -10%;
    left: -10%;
    right: -10%;
    bottom: -10%;

    transform: rotate(10deg);

    background: url(${pattern}) repeat;
  }
`;
