import React, {ElementType} from 'react';
import {Nullable} from '../../types';
import {StyledButton} from './controls';

interface ButtonProps {
  readonly startIcon?: React.ReactNode
  readonly disabled?: boolean
  readonly children: React.ReactNode
  readonly as?: ElementType
  readonly href?: string
  readonly fullWidth?: boolean
  readonly onClick?: Nullable<() => void>
}

const Button = (props: ButtonProps) => {
  const {
    startIcon,
    children,
    fullWidth = true,
    onClick,
    ...rest
  } = props;

  return (
    <StyledButton onClick={onClick || (() => null)} fullWidth={fullWidth} {...rest}>
      {startIcon && startIcon}
      {children}
    </StyledButton>
  )
}

export default Button;