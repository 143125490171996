import {StyledLogo} from './controls';
import {ReactComponent as LogoPic} from '../../media/images/logo.svg';

const Logo = () => {
  return (
    <StyledLogo>
      <LogoPic/>
      Пульс
    </StyledLogo>
  )
}

export default Logo;