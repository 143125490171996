import styled from '@emotion/styled';
import theme from '../../../../theme';

export const Wrapper = styled.div`
  padding-top: 140px;
  padding-bottom: 80px;
  
  @media (max-width: ${theme.breakpoints.md}) {
    padding-top: 120px;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    padding-top: 55px;
  }
`;

export const Cards = styled.div`
  @media (max-width: ${theme.breakpoints.md}) {
    max-width: 580px;
    
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    max-width: 100%;
  }
`;

export const CardsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-wrap: nowrap;
    
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    margin-bottom: 0;
    
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
    
    &:after {
      content: '';
      flex: 0 0 20px;
      max-width: 20px;
    }
  }
`;

export const CardsItem = styled.div`
  width: 100%;
  max-width: 100%;
  flex: 1 0 0;
  
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;

  @media (max-width: ${theme.breakpoints.md}) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    flex: 0 0 280px;
    max-width: 280px;
    
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
`;