import {AppPaletteType} from './types';

export const appPalette: AppPaletteType = {
  background: {
    default: '#fff',
    disabled: '#888888cc',
  },
  primary: {
    main: '#dF694b',
    light: '#ed7c5f',
    dark: '#cd5132',
  },
  secondary: {
    main: '#306b3d',
    light: '#4bac60',
    extraLight: '#ecfff0',
  },
  text: {
    primary: '#443835',
    primaryAlpha: '#44383555',
    light: '#fff',
    lightAlpha: '#ffffff55',
    disabled: '#666666',
  },
};
