import {BrowserRouter} from 'react-router-dom';
import ErrorBoundary from '../presentation/features/general/errorBoundary';
import MainContainer from '../presentation/features/main/container';

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <MainContainer/>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
