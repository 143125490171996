import {useLocation, useParams} from 'react-router';
import {EAppFeature} from '../../../domain/model/enums';
import {AppConfigurator} from '../../../system/appConfigurator';
import LandingContainer from '../../features/landing/container';
import {ELandingUrlParam, EModalType} from '../../features/landing/types';

const LandingScreen = () => {
  const {detailsInfo} = useParams<{ [ELandingUrlParam.DetailsInfo]: EModalType }>();
  const {search} = useLocation();

  //флаг для авто скачивания, изначально сделан на перспективу, пока используется, чтобы понять, что вошли именно из приложения
  const downloadAuto = new URLSearchParams(search).has(ELandingUrlParam.Download);

  const canIosDownload = downloadAuto || AppConfigurator.getInstance().hasFeature(EAppFeature.IosCanDownload);

  return <LandingContainer modalOpen={detailsInfo} canIosDownload={canIosDownload}/>;
};

export default LandingScreen;
