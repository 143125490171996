import {Route, Switch} from 'react-router';
import LandingEntry from '../../landing/entry';
import {Wrapper} from './controls';

const MainContainer = () => {
  return (
    <Wrapper>
      <Switch>
        <Route path="/" component={LandingEntry}/>
      </Switch>
    </Wrapper>
  );
};

export default MainContainer;