import {EAppPlatformType} from '../../../domain/model/enums';
import {Nullable} from '../../types';

export type DownloadLinkType = {
  platformType: EAppPlatformType,
  url: Nullable<string>
  canDownload: boolean
}

export enum ELandingUrlParam {
  DetailsInfo = 'detailsInfo',
  Download = 'download'
}

export type EModalType =
  EAppPlatformType.Ios
  | EAppPlatformType.AndroidGms
  | EAppPlatformType.AndroidHms
