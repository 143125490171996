import {FC} from 'react';
import {Wrapper, LinkWrapper} from './controls';

export interface LinkProps extends Partial<Pick<HTMLAnchorElement, "target" | "href" | "title">>{
  readonly color?: 'primary' | 'light'
  readonly onClick?: () => void
}

const Link: FC<LinkProps> = (props) => {
  const {href} = props;

  if (href) {
    return (
        <LinkWrapper {...props} />
    );
  }

  return (
    <Wrapper {...props}/>
  );
};

export default Link;
