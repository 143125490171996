import {useCallback, useEffect, useMemo, useState} from 'react';
import fetchDownloadLinks from '../../../data/api/fetchDownloadLinks';
import {EAppPlatformType} from '../../../domain/model/enums';
import ContentLoader from '../../components/contentLoader';
import AndroidModal from './components/downloadDialogs/android';
import AppleModal from './components/downloadDialogs/apple';
import HuaweiModal from './components/downloadDialogs/huawei';
import LandingEvents from './components/events';
import LandingFeatures from './components/features';
import LandingFeed from './components/feed';
import LandingFooter from './components/footer';
import LandingHeader from './components/header';
import LandingHelp from './components/help';
import LandingShop from './components/shop';
import LandingTeams from './components/teams';
import {Wrapper} from './controls';
import {DownloadLinkType, EModalType} from './types';

interface LandingContainerProps {
  readonly modalOpen: EModalType | null
  readonly canIosDownload: boolean
}

const LandingContainer = ({modalOpen, canIosDownload}: LandingContainerProps) => {
  const [isFetching, setFetching] = useState<boolean>(false);
  const requestedPlatform = useMemo<EAppPlatformType | null>(() => {
    switch (modalOpen) {
      case EAppPlatformType.Ios:
      case EAppPlatformType.AndroidGms:
      case EAppPlatformType.AndroidHms:
        return modalOpen;
      default:
        return null;
    }
  }, [modalOpen]);
  //так как modalOpen может быть связан с платформой, а нам открывать сразу диалог скачивания не нужно, то вводим признак который подтверждает намерение скачать
  const [internalModalOpen, setInternalModalOpen] = useState<EModalType | null>(!requestedPlatform ? modalOpen : null);
  const [downloadLinks, setDownloadLinks] = useState<DownloadLinkType[]>([]);

  const iosDownloadLink = downloadLinks.find(dl => dl.platformType === EAppPlatformType.Ios);
  const faqVisible = !requestedPlatform || (requestedPlatform === EAppPlatformType.Ios && !!iosDownloadLink?.canDownload);

  const onOpenDownloadAppleModal = () => {
    setInternalModalOpen(EAppPlatformType.Ios);
  };

  const onOpenDownloadAndroidModal = () => {
    setInternalModalOpen(EAppPlatformType.AndroidGms);
  };

  const onOpenDownloadHuaweiModal = () => {
    setInternalModalOpen(EAppPlatformType.AndroidHms);
  };

  const onCloseDownloadModal = () => {
    setInternalModalOpen(null);
  };

  const getDownloadLinks = useCallback((value: DownloadLinkType[]) => {
    setDownloadLinks((prevState) => [...prevState, ...value]);
  }, []);

  const getDownloadLinkByPlatformType = useCallback((platformType: EAppPlatformType) => {
    return downloadLinks.find(item => item.platformType === platformType);
  }, [downloadLinks]);

  useEffect(() => {
    fetchDownloadLinks({
      canIosDownload,
      requestedPlatform,
      getDownloadLinks,
      setFetching: bool => setFetching(bool)
    });
  }, [requestedPlatform, getDownloadLinks, canIosDownload]);

  return (
    <>
      {(isFetching || downloadLinks.length === 0) && <ContentLoader/>}

      <Wrapper visible={downloadLinks.length > 0}>
        <LandingHeader
          faqVisible={faqVisible}
          requestedPlatform={requestedPlatform}
          downloadLinks={downloadLinks}
          onOpenAppleModal={onOpenDownloadAppleModal}
          onOpenAndroidModal={onOpenDownloadAndroidModal}
          onOpenHuaweiModal={onOpenDownloadHuaweiModal}
        />
        <LandingFeatures/>
        <LandingFeed/>
        <LandingEvents/>
        <LandingTeams/>
        <LandingShop/>
        <LandingFooter
          iOSqrCodeUrl={downloadLinks.find(item => item.platformType === EAppPlatformType.Ios)?.url ?? ''}
          help={<LandingHelp/>}
          requestedPlatform={requestedPlatform}
          downloadLinks={downloadLinks}
          onOpenAppleModal={onOpenDownloadAppleModal}
          onOpenAndroidModal={onOpenDownloadAndroidModal}
          onOpenHuaweiModal={onOpenDownloadHuaweiModal}
        />

        {internalModalOpen === EAppPlatformType.Ios && (
          <AppleModal
            url={getDownloadLinkByPlatformType(EAppPlatformType.Ios)?.url ?? ''}
            canDownload={getDownloadLinkByPlatformType(EAppPlatformType.Ios)?.canDownload}
            onClose={onCloseDownloadModal}
          />
        )}
        {internalModalOpen === EAppPlatformType.AndroidGms && (
          <AndroidModal
            url={getDownloadLinkByPlatformType(EAppPlatformType.AndroidGms)?.url ?? ''}
            onClose={onCloseDownloadModal}
          />
        )}
        {internalModalOpen === EAppPlatformType.AndroidHms && (
          <HuaweiModal
            url={getDownloadLinkByPlatformType(EAppPlatformType.AndroidHms)?.url ?? ''}
            onClose={onCloseDownloadModal}
          />
        )}
      </Wrapper>
    </>
  );
};

export default LandingContainer;
