import styled from '@emotion/styled';
import theme from '../../theme';

export const Wrapper = styled.div`
  max-width: 1280px;

  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  
  @media (max-width: ${theme.breakpoints.lg}) {
    max-width: 924px;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    max-width: 768px;
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;