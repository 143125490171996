import {Wrapper, Inner, PicDecor, Content, Media, MediaInner, MediaContent, MediaPicWrapper, MediaPic, Details, DetailsInner, Title, Description} from './controls';
import Container from '../../../../components/container';
import {Picture} from 'react-responsive-picture';

import coin from '../../../../media/images/coin.png';
import coin_2x from '../../../../media/images/coin@2x.png';
import coinTablet from '../../../../media/images/coin_tablet.png';
import coinTablet_2x from '../../../../media/images/coin_tablet@2x.png';
import theme from '../../../../theme';
import {useMediaQuery} from 'beautiful-react-hooks';
import eventsMobile from '../../../../media/images/events_mobile.png';
import eventsMobile_2x from '../../../../media/images/events_mobile@2x.png';
import eventsTabletPortrait from '../../../../media/images/events_tabletPortrait.png';
import eventsTabletPortrait_2x from '../../../../media/images/events_tabletPortrait@2x.png';
import eventsTabletLandscape from '../../../../media/images/events_tabletLandscape.png';
import eventsTabletLandscape_2x from '../../../../media/images/events_tabletLandscape@2x.png';
import events from '../../../../media/images/events.png';
import events_2x from '../../../../media/images/events@2x.png';

const LandingEvents = () => {
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Wrapper>
      <Inner>
        {!isMobile && (
          <PicDecor>
            <Picture
              sources={[
                {
                  srcSet: `${coinTablet}, ${coinTablet_2x} 2x`,
                  media: `(max-width: ${theme.breakpoints.lg})`,
                },
                {
                  srcSet: `${coin}, ${coin_2x} 2x`,
                },
              ]}
            />
          </PicDecor>
        )}
        <Container>
          <Content>
            <Media>
              <MediaInner>
                <MediaContent>
                  <MediaPicWrapper>
                    <MediaPic>
                      <Picture
                        sources={[
                          {
                            srcSet: `${eventsMobile}, ${eventsMobile_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.sm})`,
                          },
                          {
                            srcSet: `${eventsTabletPortrait}, ${eventsTabletPortrait_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.md})`,
                          },
                          {
                            srcSet: `${eventsTabletLandscape}, ${eventsTabletLandscape_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.lg})`,
                          },
                          {
                            srcSet: `${events}, ${events_2x} 2x`,
                          },
                        ]}
                      />
                    </MediaPic>
                  </MediaPicWrapper>
                </MediaContent>
              </MediaInner>
            </Media>
            <Details>
              <DetailsInner>
                <Title>Мероприятия</Title>
                <Description>Участвуй в&nbsp;соревнованиях компании или создавай свои</Description>
              </DetailsInner>
            </Details>
          </Content>
        </Container>
      </Inner>
    </Wrapper>
  );
};

export default LandingEvents;