import styled from "@emotion/styled";
import theme from "../../../../../../theme";

export const FooterQRCodeWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 255px;
  padding: 2rem;
  margin-top: 1rem;
  background-color: white;

  & > svg {
    vertical-align: top;
  }
`;


export const FooterQRCodeTitle = styled.h2`
  color: ${theme.palette.text.primary};
  font-size: .75rem;
  text-align: center;
  margin-bottom: 1rem;
`
