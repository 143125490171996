import {
  Wrapper,
  Inner,
  PicDecor,
  Content,
  Media,
  MediaInner,
  MediaContent,
  MediaPicWrapper,
  MediaPic,
  Details,
  DetailsInner,
  Title,
  Description
} from './controls';
import Container from '../../../../components/container';
import {Picture} from 'react-responsive-picture';
import theme from '../../../../theme';
import {useMediaQuery} from 'beautiful-react-hooks';

import puck from '../../../../media/images/puck.png';
import puck_2x from '../../../../media/images/puck@2x.png';
import puckTablet from '../../../../media/images/puck_tablet.png';
import puckTablet_2x from '../../../../media/images/puck_tablet@2x.png';
import feed from '../../../../media/images/feed.png';
import feed_2x from '../../../../media/images/feed@2x.png';
import feedTabletLandscape from '../../../../media/images/feed_tabletLandscape.png';
import feedTabletLandscape_2x from '../../../../media/images/feed_tabletLandscape@2x.png';
import feedTabletPortrait from '../../../../media/images/feed_tabletPortrait.png';
import feedTabletPortrait_2x from '../../../../media/images/feed_tabletPortrait@2x.png';
import feedMobile from '../../../../media/images/feed_mobile.png';
import feedMobile_2x from '../../../../media/images/feed_mobile@2x.png';

const LandingFeed = () => {
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Wrapper>
      <Inner>
        {!isMobile && (
          <PicDecor>
            <Picture
              sources={[
                {
                  srcSet: `${puckTablet}, ${puckTablet_2x} 2x`,
                  media: `(max-width: ${theme.breakpoints.lg})`,
                },
                {
                  srcSet: `${puck}, ${puck_2x} 2x`,
                },
              ]}
            />
          </PicDecor>
        )}
        <Container>
          <Content>
            <Media>
              <MediaInner>
                <MediaContent>
                  <MediaPicWrapper>
                    <MediaPic>
                      <Picture
                        sources={[
                          {
                            srcSet: `${feedMobile}, ${feedMobile_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.sm})`,
                          },
                          {
                            srcSet: `${feedTabletPortrait}, ${feedTabletPortrait_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.md})`,
                          },
                          {
                            srcSet: `${feedTabletLandscape}, ${feedTabletLandscape_2x} 2x`,
                            media: `(max-width: ${theme.breakpoints.lg})`,
                          },
                          {
                            srcSet: `${feed}, ${feed_2x} 2x`,
                          },
                        ]}
                      />
                    </MediaPic>
                  </MediaPicWrapper>
                </MediaContent>
              </MediaInner>
            </Media>
            <Details>
              <DetailsInner>
                <Title>Лента</Title>
                <Description>Актуальные мероприятия и&nbsp;команды в&nbsp;ленте событий</Description>
              </DetailsInner>
            </Details>
          </Content>
        </Container>
      </Inner>
    </Wrapper>
  );
};

export default LandingFeed;