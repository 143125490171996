import styled from '@emotion/styled';
import theme from '../../../../theme';

export const Wrapper = styled.section`
`;

export const Inner = styled.div`
  max-width: 1440px;
  padding-top: 90px;
  padding-bottom: 60px;
  
  position: relative;
  
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${theme.breakpoints.lg}) {
    padding-top: 80px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    padding-top: 70px;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    padding-top: 56px;
  }
`;

export const PicDecor = styled.div`
  position: absolute;
  left: -90px;
  top: -40px;

  @media (max-width: ${theme.breakpoints.lg}) {
    top: -70px;
    left: -90px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    left: -120px;
  }
`;

export const Content = styled.div`
  max-width: 980px;
  
  display: flex;
  align-items: center;
  
  padding-left: 30px;
  margin-left: auto;
  margin-right: auto;
  
  @media (max-width: ${theme.breakpoints.lg}) {
    max-width: 656px;
    
    padding-left: 26px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    max-width: 596px;

    padding-left: 22px;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    flex-wrap: wrap;
    
    padding-left: 0;
  }
`;

export const Media = styled.div`
  flex: 0 0 429px;
  max-width: 429px;

  @media (max-width: ${theme.breakpoints.lg}) {
    flex: 0 0 356px;
    max-width: 356px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    flex: 0 0 306px;
    max-width: 306px;
  }
  
  @media (max-width: ${theme.breakpoints.sm}) {
    flex: 0 0 100%;
    max-width: 100%;
    
    margin-bottom: 16px;
  }
`;

export const MediaInner = styled.div`
  @media (max-width: ${theme.breakpoints.sm}) {  
    width: 250px;
    
    margin-left: auto;
    margin-right: auto;
  }
`;

export const MediaContent = styled.div`
  padding-bottom: 100%;
  position: relative;
  
  border-radius: 50%;
`;

export const MediaPicWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const MediaPic = styled.div`
  width: 100%;
  
  img {
    width: 100%;
  }
`;

export const Details = styled.div`
  padding-left: 80px;

  @media (max-width: ${theme.breakpoints.lg}) {
    padding-left: 36px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    padding-left: 32px;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    padding-left: 0;
    
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const DetailsInner = styled.div`
  max-width: 272px;

  @media (max-width: ${theme.breakpoints.sm}) {
    text-align: center;
    
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Title = styled.h2`
  font-size: ${theme.typography.h2.fontSize};
  line-height: ${theme.typography.h2.lineHeight};
  
  margin-bottom: 10px;

  @media (max-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.typography.subtitle.fontSize};
    line-height: ${theme.typography.subtitle.lineHeight};
    
    margin-bottom: 8px;
  }
`;

export const Description = styled.p`
  @media (max-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.typography.textSmall.fontSize};
    line-height: ${theme.typography.textSmall.lineHeight};
  }
`;

