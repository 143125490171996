import {StyledCard, Content, IconWrapper, Title, Description, Pattern} from './controls';
import React from 'react';
import {isSafari} from '../../features/landing/utils';

interface CardProps {
  readonly icon: React.ReactNode
  readonly title: React.ReactNode
  readonly description: React.ReactNode
}

const Card = (props: CardProps) => {
  const {
    icon,
    title,
    description
  } = props;

  return (
    <StyledCard>
      {!isSafari && <Pattern/>}
      <Content>
        <IconWrapper>
          {icon}
        </IconWrapper>
        <Title>
          {title}
        </Title>
        <Description>
          {description}
        </Description>
      </Content>
    </StyledCard>
  );
};

export default Card;