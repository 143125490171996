import styled from '@emotion/styled';
import pattern from '../../../../media/pattern.svg';
import theme from '../../../../theme';

export const Wrapper = styled.div`
  background: linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.light} 100%);
  color: ${theme.palette.text.light};

  position: relative;

  padding-top: 84px;
  padding-bottom: 160px;
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 56px;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 24px;
`;

export const HelpWrapper = styled.div`
  margin-top: 32px;
`;

export const AccordionWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

export const Pattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;

  &:before {
    content: '';
    opacity: .05;

    position: absolute;
    top: -10%;
    left: -10%;
    right: -10%;
    bottom: -10%;

    transform: rotate(10deg);

    background: url(${pattern}) repeat;
  }
`;


export const AccordionList = styled.ol``
export const AccordionListItem = styled.li`
  margin-bottom: 1.25rem;
  
  img {
    margin-top: .75rem;
  }
`
