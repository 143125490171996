import styled from '@emotion/styled';
import theme from '../../theme';
import {Nullable} from '../../types';

type WrapperProps = {
  readonly fadeType: Nullable<'in' | 'out'>
}

type ContentProps = {
  readonly dense?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 100;

  ${p => p.fadeType === 'in' && `
    opacity: 1;
    transition: opacity .2s;
  `};

  ${p => p.fadeType === 'out' && `
    opacity: 0;
    transition: opacity .2s;
  `};
`;

export const Content = styled.div<ContentProps>`
  width: 580px;
  max-height: 80%;

  ${p => p.dense
          ? `padding: 32px 24px;`
          : `padding: 68px 58px;`
  };

  display: flex;
  position: relative;

  border-radius: 8px;
  background-color: ${theme.palette.background.default};

  @media (max-width: ${theme.breakpoints.md}) {
    width: 500px;

    padding: 32px 24px;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
    height: 100%;
    max-height: 100%;

    border-radius: 0;

    padding-top: 80px;
  }
`;

export const Close = styled.button`
  position: absolute;
  left: 100%;
  top: 0;

  width: 32px;
  height: 32px;

  margin: 0;
  padding: 0;

  border: 0;
  background: none;
  cursor: pointer;
  color: ${theme.palette.text.light};
  transition: .2s ease-in-out;

  & svg {
    vertical-align: middle;
  }

  &:hover {
    color: ${theme.palette.primary.light};
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    left: auto;
    top: 24px;
    right: 16px;

    color: ${theme.palette.text.primary};
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, .5);
`;
