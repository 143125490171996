import dotenv from 'dotenv';
import {EAppFeature} from '../domain/model/enums';

export type AppConfiguratorOptions = {
  readonly apiBase: string
  readonly appIdAndroidGms: string
  readonly appIdAndroidHms: string
  readonly appIdIos: string
  readonly features: string[]
  readonly support: {
    readonly termsOfUseUrl: string;
    readonly personalDataPolicyUrl: string;
  }
};

export type AppEnv = {
  readonly apiBase: string
  readonly appIdAndroidGms: string
  readonly appIdAndroidHms: string
  readonly appIdIos: string
  readonly features: string
  readonly supportTermsOfUseUrl: string
  readonly supportPersonalDataPolicyUrl: string
};

export class AppConfigurator {
  private static instance: AppConfigurator;

  public static getInstance(): AppConfigurator {
    if (!AppConfigurator.instance) {
      AppConfigurator.instance = new AppConfigurator();
    }

    return AppConfigurator.instance;
  }

  private constructor() {
  }

  private options: AppConfiguratorOptions = AppConfigurator.init();

  private static loadEnv(): AppEnv {
    if (process.env.NODE_ENV === 'test') {
      return {
        apiBase: '',
        appIdAndroidGms: '',
        appIdAndroidHms: '',
        appIdIos: '',
        features: '',
        supportTermsOfUseUrl: '',
        supportPersonalDataPolicyUrl: '',
      }
    } else {
      const request = new XMLHttpRequest();
      request.open('GET', '/config/app.env', false);
      request.send();
      if (request.status === 200) {
        const options: AppEnv = dotenv.parse<AppEnv>(request.responseText, {debug: process.env.NODE_ENV === 'development'});
        console.log('app options', options);
        return options;
      }
      throw Error('Not found application config');
    }
  }

  private static init(): AppConfiguratorOptions {
    const env = AppConfigurator.loadEnv();
    return {
      apiBase: env.apiBase,
      appIdAndroidGms: env.appIdAndroidGms,
      appIdAndroidHms: env.appIdAndroidHms,
      appIdIos: env.appIdIos,
      features: env.features?.split(',') ?? [],
      support: {
        termsOfUseUrl: env.supportTermsOfUseUrl,
        personalDataPolicyUrl: env.supportPersonalDataPolicyUrl,
      }
    };
  }

  getOptions(): AppConfiguratorOptions {
    return this.options;
  }

  getApiBase() {
    return this.options.apiBase;
  }

  hasFeature(feature: EAppFeature) {
    return this.options.features.includes(feature);
  }

}
