export enum EAppPlatformType {
  Ios = 'ios',
  AndroidGms = 'android-gms',
  AndroidHms = 'android-hms',
}

export enum EAppFeature {
  TermsOfUse  = 'terms',
  PersonalDataPolicy = 'pdp',
  IosCanDownload = 'iosCanDownload',
}
