import styled from "@emotion/styled/macro";
import theme from "../../theme";

export const StyledAccordion = styled.div`
  margin-bottom: -12px;

  .ReactCollapse--collapse {
    transition: height 0.3s;
  }
`;

export const Header = styled.div`
  position: relative;
  padding-right: 60px;

  cursor: pointer;

  &:before {
    content: "";

    position: absolute;
    top: -15px;
    left: -15px;
    bottom: -15px;
    right: -15px;
  }
`;

export const Content = styled.div`
  padding-top: 15px;

  ol {
    margin: 0;
    padding: 0 0 0 20px;
  }

  li {
    max-width: 40%;

    div {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }

    img {
      object-fit: fill;
      height: auto;
      max-width: 100%;
    }
  }

  @media (max-width: ${theme.breakpoints.lg}) {
    max-width: 660px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    max-width: 440px;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.typography.textSmall.fontSize};
    line-height: ${theme.typography.textSmall.lineHeight};

    max-width: none;

    li {
      max-width: none;
    }
  }
`;

export const Title = styled.h3`
  font-size: ${theme.typography.subtitle.fontSize};
  line-height: ${theme.typography.subtitle.lineHeight};
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;

  position: absolute;
  right: 0;
  top: 0;

  transform: rotate(0);
  transition: transform 0.3s;
  transform-origin: center center;

  > svg {
    fill: #e2f2e6;
  }
`;

export const StyledAccordionItem = styled.div`
  padding: 30px;
  margin-bottom: 12px;

  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;

  &.is-active {
    ${IconWrapper} {
      transform: rotate(-180deg);
    }
  }
`;
