import {Wrapper} from './controls';
import React from 'react';

interface ContainerProps {
  readonly children: React.ReactNode
}

const Container = ({children}: ContainerProps) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default Container;