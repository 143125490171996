import styled from '@emotion/styled';
import theme from '../../theme';

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${theme.palette.background.default};
`;

export const Spinner = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${theme.palette.primary.light};

  border-radius: 100%;
  animation: sk-scaleOut 1s infinite ease-in-out;

  @keyframes sk-scaleOut {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1.0);
      opacity: 0;
    }
  }
`;
