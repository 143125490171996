import styled from '@emotion/styled';
import theme from '../../theme';
import {LinkProps} from './index';

export const Wrapper = styled.span<LinkProps>`
  font-size: ${theme.typography.textSmall.fontSize};
  line-height: ${theme.typography.textSmall.lineHeight};

  border-bottom: 1px dashed;
  border-bottom-color: ${p => p.color === 'light' ? theme.palette.text.lightAlpha : theme.palette.text.primaryAlpha};
  color: ${p => p.color === 'light' ? theme.palette.text.light : theme.palette.text.primary};

  transition: .2s ease-in-out;
  cursor: pointer;

  &:hover {
    border-bottom-color: ${p => p.color === 'light' ? theme.palette.text.light : theme.palette.text.primary};
  }
`;

export const LinkWrapper = styled.a<LinkProps>`
  font-size: ${theme.typography.textSmall.fontSize};
  line-height: ${theme.typography.textSmall.lineHeight};
  text-decoration: none;

  border-bottom: 1px dashed;
  border-bottom-color: ${p => p.color === 'light' ? theme.palette.text.lightAlpha : theme.palette.text.primaryAlpha};
  color: ${p => p.color === 'light' ? theme.palette.text.light : theme.palette.text.primary};

  transition: .2s ease-in-out;
  cursor: pointer;

  &:hover {
    border-bottom-color: ${p => p.color === 'light' ? theme.palette.text.light : theme.palette.text.primary};
  }
`;

