import styled from '@emotion/styled/macro';
import theme from '../../../../theme';

export const Title = styled.p`
  font-size: ${theme.typography.textSmall.fontSize};
  line-height: ${theme.typography.textSmall.lineHeight};
  
  margin-bottom: 12px;
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.div`
`;

type WrapperProps = {
  isHeaderPosition: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  ${p => p.isHeaderPosition && ButtonGroup} {
    margin-bottom: 8px;
    margin-left: -10px;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;

      max-width: 320px;

      margin-left: 0;
      margin-bottom: -2px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      max-width: 375px;

      margin-left: auto;
      margin-right: auto;
      margin-bottom: 6px;
    }
  };

  ${p => p.isHeaderPosition && ButtonWrapper} {
    margin-left: 10px;

    @media (max-width: ${theme.breakpoints.md}) {
      flex: 0 0 100%;
      max-width: 100%;

      margin-left: 0;
      margin-bottom: 10px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  };

  ${p => !p.isHeaderPosition && ButtonGroup} {
    justify-content: center;

    @media (max-width: ${theme.breakpoints.sm}) {
      max-width: 375px;
      flex-wrap: wrap;

      margin-left: auto;
      margin-right: auto;
    }
  };

  ${p => !p.isHeaderPosition && ButtonWrapper} {
    padding-left: 6px;
    padding-right: 6px;

    @media (max-width: ${theme.breakpoints.sm}) {
      flex: 0 0 100%;
      max-width: 100%;

      padding-left: 0;
      padding-right: 0;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
`;