import {Wrapper, Cards, CardsList, CardsItem} from './controls';
import Container from '../../../../components/container';
import Card from '../../../../components/card';

import iconNumber from '../../../../media/icons/iconNumber.png';
import iconNumber_2x from '../../../../media/icons/iconNumber@2x.png';
import iconUsers from '../../../../media/icons/iconUsers.png';
import iconUsers_2x from '../../../../media/icons/iconUsers@2x.png';
import iconBolt from '../../../../media/icons/iconBolt.png';
import iconBolt_2x from '../../../../media/icons/iconBolt@2x.png';
import iconStar from '../../../../media/icons/iconStar.png';
import iconStar_2x from '../../../../media/icons/iconStar@2x.png';

const LandingFeatures = () => {
  return (
    <Wrapper>
      <Container>
        <Cards>
          <CardsList>
            <CardsItem>
              <Card
                icon={<img src={iconNumber} srcSet={`${iconNumber_2x} 2x`} alt='' />}
                title='Мероприятия'
                description='Участвуйте в спартакиадах и турнирах, находите соперников для товарищеских матчей'
              />
            </CardsItem>
            <CardsItem>
              <Card
                icon={<img src={iconUsers} srcSet={`${iconUsers_2x} 2x`} alt='' />}
                title='Команды'
                description='Вступайте в команды или создавайте свои'
              />
            </CardsItem>
            <CardsItem>
              <Card
                icon={<img src={iconBolt} srcSet={`${iconBolt_2x} 2x`} alt='' />}
                title='Бонусы'
                description='Больше побед - больше бонусов!'
              />
            </CardsItem>
            <CardsItem>
              <Card
                icon={<img src={iconStar} srcSet={`${iconStar_2x} 2x`} alt='' />}
                title='Получай призы'
                description='Зарабатывай бонусные баллы'
              />
            </CardsItem>
          </CardsList>
        </Cards>
      </Container>
    </Wrapper>
  );
};

export default LandingFeatures;