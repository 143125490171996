import {appPalette} from './palette';
import {appTypography} from './typography';
import {appBreakpoints} from './breakpoints';

const theme = {
  typography: {...appTypography},
  palette: {...appPalette},
  breakpoints: {...appBreakpoints}
}

export default theme;