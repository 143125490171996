import {AppTypographyType} from './types';

export const appTypography: AppTypographyType = {
  fontFamily: `'Open Sans', sans-serif`,
  h1: {
    fontSize: '2.125rem',
    lineHeight: '2.875rem',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
  },
  text: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  textSmall: {
    fontSize: '.875rem',
    lineHeight: '1.125rem',
  },
};
