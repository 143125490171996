import {FC, useState} from 'react';
import {Nullable} from '../../types';
import {Collapse} from 'react-collapse';
import {StyledAccordion, StyledAccordionItem, Header, Title, IconWrapper, Content} from './controls';
import {ReactComponent as IconChevronDown} from '../../media/icons/iconChevronDown.svg';

type DataProps = {
  readonly title: string
  readonly content: FC
}

interface AccordionProps {
  readonly data: DataProps[]
  readonly initialActiveIndex?: Nullable<number>
}

const Accordion = (props: AccordionProps) => {
  const {
    data,
    initialActiveIndex = null
  } = props;

  const [activeIndex, setActiveIndex] = useState<Nullable<number>>(initialActiveIndex);

  return (
    <StyledAccordion>
      {data.map((item, index) => {
        return (
          <AccordionItem
            key={index}
            {...item}
            isActive={activeIndex === index}
            onToggle={() => setActiveIndex(prevState => prevState === index ? null : index)}
          />
        )}
      )}
    </StyledAccordion>
  );
};

interface AccordionItemProps {
  readonly title: string
  readonly content: FC
  readonly isActive: boolean
  readonly onToggle: () => void
}

const AccordionItem = (props: AccordionItemProps) => {
  const {
    title,
    content: ContentComponent,
    isActive,
    onToggle
  } = props;

  return (
    <StyledAccordionItem className={`${isActive ? 'is-active' : ''}`}>
      <Header onClick={onToggle}>
        <Title>{title}</Title>
        <IconWrapper>
          <IconChevronDown />
        </IconWrapper>
      </Header>
      <Collapse isOpened={isActive}>
        <Content>
          <ContentComponent />
        </Content>
      </Collapse>
    </StyledAccordionItem>
  );
};

export default Accordion;
