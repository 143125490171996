import QRCode from 'react-qr-code';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import {ReactComponent as IconAndroid} from '../../../../media/icons/iconAndroid.svg';
import {QrCodeSize} from '../../../../utils/constants';
import {ButtonWrapper, Caption, Content, QRCodeWrapper, Title} from './controls';

interface AndroidModalProps {
  readonly url: string
  readonly onClose: () => void
}

const AndroidModal = ({url, onClose}: AndroidModalProps) => {
  return (
    <Modal onClose={onClose}>
      <Content>
        <Title>Считайте камерой телефона QR код для скачивания</Title>
        <QRCodeWrapper>
          <QRCode value={url} size={QrCodeSize}/>
        </QRCodeWrapper>
        <ButtonWrapper>
          <Caption>или скачайте apk файл для установки</Caption>
          <Button
            startIcon={<IconAndroid/>}
            as="a"
            href={url}
          >
            Скачать приложение
          </Button>
        </ButtonWrapper>
      </Content>
    </Modal>
  );
};

export default AndroidModal;
