import QRCode from "react-qr-code";
import {FooterQrCodeSize} from "../../../../../../utils/constants";
import {FooterQRCodeTitle, FooterQRCodeWrapper} from "./controls";

interface FooterQRProps {
    iOSqrCodeUrl: string;
}
const QRCodeTitleText = "Считайте камерой телефона QR код для скачивания"

export const FooterQR = ({iOSqrCodeUrl}: FooterQRProps) => {
    return (
        <FooterQRCodeWrapper>
            <FooterQRCodeTitle>{QRCodeTitleText}</FooterQRCodeTitle>
            <QRCode value={iOSqrCodeUrl} size={FooterQrCodeSize}/>
        </FooterQRCodeWrapper>
    )
}
