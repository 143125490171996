import {FC, useEffect, useState} from 'react';
import ReactDom from 'react-dom';
import {ReactComponent as IconClose} from '../../media/icons/iconClose.svg';
import {Nullable} from '../../types';
import {Close, Content, Overlay, Wrapper} from './controls';

const modalRoot: Nullable<HTMLElement> = document.getElementById('modal-root');

interface ModalProps {
  readonly dense?: boolean
  readonly onClose: () => void
}

const Modal: FC<ModalProps> = ({dense, children, onClose}) => {
  const [fadeType, setFadeType] = useState<Nullable<'in' | 'out'>>(null);

  const handleClose = () => {
    setFadeType('out');
  }

  const onEscKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setFadeType('out');
    }
  };

  const onTransitionEnd = () => {
    if (fadeType === 'in') return;

    if (fadeType === 'out') {
      onClose();
    }
  };

  useEffect(() => {
    setTimeout(() => setFadeType('in'), 0);

    window.addEventListener('keydown', onEscKeyDown);

    return () => {
      window.removeEventListener('keydown', onEscKeyDown);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    }
  }, [])

  return (
    ReactDom.createPortal(
      <Wrapper
        onTransitionEnd={onTransitionEnd}
        fadeType={fadeType}
      >
        <Overlay onClick={handleClose}/>
        <Content role="dialog"
                 dense={dense}>
          <Close onClick={handleClose}>
            <IconClose/>
          </Close>
          {children}
        </Content>
      </Wrapper>,
      modalRoot!
    )
  );
};

export default Modal;
