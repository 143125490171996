import {getUseragentInfo} from '@mp-npm/useragent-info';
import {EAppPlatformType} from '../../../../../domain/model/enums';
import Button from '../../../../components/button';
import {ReactComponent as IconAndroid} from '../../../../media/icons/iconAndroid.svg';
import {ReactComponent as IconApple} from '../../../../media/icons/iconApple.svg';
import {ReactComponent as IconHuawei} from '../../../../media/icons/iconHuawei.svg';
import {Nullable} from '../../../../types';
import {DownloadLinkType} from '../../types';
import {getPlatformsByRequestedPlatform} from '../../utils';
import {ButtonGroup, ButtonWrapper, Title, Wrapper} from './controls';

interface DownloadButtonsProps {
  readonly requestedPlatform: EAppPlatformType | null
  readonly isHeaderPosition?: boolean
  readonly downloadLinks: DownloadLinkType[]
  readonly onOpenAppleModal: () => void
  readonly onOpenAndroidModal: () => void
  readonly onOpenHuaweiModal: () => void
}

const DownloadButtons = (props: DownloadButtonsProps) => {
  const {
    requestedPlatform,
    isHeaderPosition = false,
    downloadLinks,
    onOpenAppleModal,
    onOpenAndroidModal,
    onOpenHuaweiModal
  } = props;

  const Buttons = () => {
    const {isAndroid, isIOS, isHmsSupported, isMobileDevice} = getUseragentInfo();

    const platformType = getPlatformsByRequestedPlatform(requestedPlatform);

    if (downloadLinks.length === 0) {
      return null;
    }

    const iosDownloadLink = downloadLinks.find(dl => dl.platformType === EAppPlatformType.Ios);
    const androidGmsDownloadLink = downloadLinks.find(dl => dl.platformType === EAppPlatformType.AndroidGms);
    const androidHmsDownloadLink = downloadLinks.find(dl => dl.platformType === EAppPlatformType.AndroidHms);

    if (!isMobileDevice) {
      return (
        <>
          <Title>Скачать приложение</Title>
          <ButtonGroup>
            <DesktopButtons
              platformType={platformType}
              onOpenAppleModal={iosDownloadLink?.url ? onOpenAppleModal : null}
              onOpenAndroidModal={androidGmsDownloadLink?.url ? onOpenAndroidModal : null}
              onOpenHuaweiModal={androidHmsDownloadLink?.url ? onOpenHuaweiModal : null}
            />
          </ButtonGroup>
        </>
      );
    } else if (platformType[EAppPlatformType.AndroidHms] || isHmsSupported) {
      return (
        <ButtonGroup>
          <ButtonWrapper>
            <Button
              startIcon={<IconHuawei/>}
              as="a"
              disabled={!downloadLinks[0].url}
              href={downloadLinks[0].url ?? '#'}
            >
              Скачать приложение
            </Button>
          </ButtonWrapper>
        </ButtonGroup>
      );
    } else if (platformType[EAppPlatformType.AndroidGms] || isAndroid) {
      return (
        <ButtonGroup>
          <ButtonWrapper>
            <Button
              startIcon={<IconAndroid/>}
              as="a"
              disabled={!downloadLinks[0].url}
              href={downloadLinks[0].url ?? '#'}
            >
              Скачать приложение
            </Button>
          </ButtonWrapper>
        </ButtonGroup>
      );
    } else if (platformType[EAppPlatformType.Ios] || isIOS) {
      return (
        <ButtonGroup>
          <ButtonWrapper>
            {(!iosDownloadLink || iosDownloadLink?.canDownload) && (
              <Button
                startIcon={<IconApple/>}
                as="a"
                disabled={!downloadLinks[0].url}
                href={downloadLinks[0].url ?? '#'}
              >
                Скачать приложение
              </Button>
            )}
            {(iosDownloadLink && !iosDownloadLink.canDownload) && (
              <Button
                startIcon={<IconApple/>}
                onClick={onOpenAppleModal}
              >
                iPhone
              </Button>
            )}
          </ButtonWrapper>
        </ButtonGroup>
      );
    } else {
      return (
        <>
          <Title>Скачать приложение</Title>
          <ButtonGroup>
            <ButtonWrapper>
              {(!iosDownloadLink || iosDownloadLink?.canDownload) && (
                <Button
                  startIcon={<IconApple/>}
                  as="a"
                  disabled={!downloadLinks[0].url}
                  href={iosDownloadLink?.url ?? '#'}
                >
                  iPhone
                </Button>
              )}
              {(iosDownloadLink && !iosDownloadLink.canDownload) && (
                <Button
                  startIcon={<IconApple/>}
                  onClick={onOpenAppleModal}
                >
                  iPhone
                </Button>
              )}
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                startIcon={<IconAndroid/>}
                as="a"
                disabled={!downloadLinks[0].url}
                href={androidGmsDownloadLink?.url ?? '#'}
              >
                Android
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                startIcon={<IconHuawei/>}
                as="a"
                disabled={!downloadLinks[0].url}
                href={androidHmsDownloadLink?.url ?? '#'}
              >
                Huawei
              </Button>
            </ButtonWrapper>
          </ButtonGroup>
        </>
      );
    }
  };

  return (
    <Wrapper isHeaderPosition={isHeaderPosition}>
      <Buttons/>
    </Wrapper>
  );
};

export default DownloadButtons;

interface DesktopButtonsProps {
  readonly platformType: { [key in EAppPlatformType]: boolean }
  readonly onOpenAppleModal: Nullable<() => void>
  readonly onOpenAndroidModal: Nullable<() => void>
  readonly onOpenHuaweiModal: Nullable<() => void>
}

const DesktopButtons = (props: DesktopButtonsProps) => {
  const {
    platformType,
    onOpenAppleModal,
    onOpenAndroidModal,
    onOpenHuaweiModal
  } = props;

  if (platformType[EAppPlatformType.Ios]) {
    return (
      <ButtonWrapper>
        <Button startIcon={<IconApple/>} disabled={!onOpenAppleModal} onClick={onOpenAppleModal}>iPhone</Button>
      </ButtonWrapper>
    );
  } else if (platformType[EAppPlatformType.AndroidGms]) {
    return (
      <ButtonWrapper>
        <Button startIcon={<IconAndroid/>} disabled={!onOpenAndroidModal} onClick={onOpenAndroidModal}>Android</Button>
      </ButtonWrapper>
    );
  } else if (platformType[EAppPlatformType.AndroidHms]) {
    return (
      <ButtonWrapper>
        <Button startIcon={<IconHuawei/>} disabled={!onOpenHuaweiModal} onClick={onOpenHuaweiModal}>Huawei</Button>
      </ButtonWrapper>
    );
  } else {
    return (
      <>
        <ButtonWrapper>
          <Button startIcon={<IconApple/>} disabled={!onOpenAppleModal} onClick={onOpenAppleModal}>iPhone</Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button startIcon={<IconAndroid/>} disabled={!onOpenAndroidModal}
                  onClick={onOpenAndroidModal}>Android</Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button startIcon={<IconHuawei/>} disabled={!onOpenHuaweiModal} onClick={onOpenHuaweiModal}>Huawei</Button>
        </ButtonWrapper>
      </>
    );
  }
};
