import {Route, Switch} from 'react-router';
import LandingScreen from '../../screens/landing';
import {ELandingUrlParam, EModalType} from './types';

const routing = {
  root: '/',
  detailsInfo: `/:${ELandingUrlParam.DetailsInfo}`
}

interface GetLandingRouteProps {
  readonly detailsInfo?: EModalType | null
}

export const getLandingRoute = ({detailsInfo}: GetLandingRouteProps) => {
  return `${routing.detailsInfo.replace(`:${ELandingUrlParam.DetailsInfo}`, detailsInfo ?? '')}`
}

const LandingEntry = () => {
  return (
    <Switch>
      <Route exact path={routing.detailsInfo} component={LandingScreen}/>
      <Route path={routing.root} component={LandingScreen}/>
    </Switch>
  );
};

export default LandingEntry;
