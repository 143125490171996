import styled from '@emotion/styled';
import theme from '../../theme';
import pattern from '../../media/pattern.svg';

export const StyledCard = styled.div`
  height: 100%;
  
  text-align: center;
  padding: 34px 20px 42px;

  background: linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.light} 100%);
  color: ${theme.palette.text.light};
  border-radius: 8px;
  
  position: relative;
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  margin-bottom: 20px;
  
  pointer-events: none;
`;

export const Title = styled.h3`
  font-size: ${theme.typography.subtitle.fontSize};
  line-height: ${theme.typography.subtitle.lineHeight};
  
  margin-bottom: 8px;
`;

export const Description = styled.p`
  font-size: ${theme.typography.textSmall.fontSize};
  line-height: ${theme.typography.textSmall.lineHeight};
`;

export const Pattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  overflow: hidden;

  border-radius: 8px;

  &:before {
    content: '';
    opacity: .05;

    position: absolute;
    top: -10%;
    left: -10%;
    right: -10%;
    bottom: -10%;

    transform: rotate(10deg);

    background: url(${pattern}) repeat;
  }
`;