import styled from '@emotion/styled';
import theme from '../../theme';

export const StyledLogo = styled.div`
  height: 40px;
  
  display: inline-flex;
  align-items: center;
  
  font-size: 27px;
  line-height: 45px;
  font-weight: 600;
  color: ${theme.palette.text.light};

  @media (max-width: ${theme.breakpoints.md}) {
    height: 35px;
    
    font-size: 21px;
    line-height: 35px;
  }

  & > svg {
    width: 60px;
    height: 60px;
    
    margin-right: 18px;
    fill: #fff;

    @media (max-width: ${theme.breakpoints.md}) {
      width: 50px;
      height: 50px;
      
      margin-right: 14px;
    }
  }
`;