import {EAppPlatformType} from '../../../domain/model/enums';

export const getPlatformsByRequestedPlatform = (requestedPlatform: EAppPlatformType | null) => {
  if (requestedPlatform === EAppPlatformType.Ios) {
    return {
      [EAppPlatformType.Ios]: true,
      [EAppPlatformType.AndroidGms]: false,
      [EAppPlatformType.AndroidHms]: false,
    };
  } else if (requestedPlatform === EAppPlatformType.AndroidGms) {
    return {
      [EAppPlatformType.Ios]: false,
      [EAppPlatformType.AndroidGms]: true,
      [EAppPlatformType.AndroidHms]: false,
    };
  } else if (requestedPlatform === EAppPlatformType.AndroidHms) {
    return {
      [EAppPlatformType.Ios]: false,
      [EAppPlatformType.AndroidGms]: false,
      [EAppPlatformType.AndroidHms]: true,
    };
  } else {
    return {
      [EAppPlatformType.Ios]: false,
      [EAppPlatformType.AndroidGms]: false,
      [EAppPlatformType.AndroidHms]: false,
    };
  }
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
