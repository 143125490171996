import {getUseragentInfo} from '@mp-npm/useragent-info';
import {EAppFeature, EAppPlatformType} from '../../domain/model/enums';
import {DownloadLinkType} from '../../presentation/features/landing/types';
import {getAppVersionUrl} from '../../presentation/utils';
import {AppConfigurator} from '../../system/appConfigurator';

interface FetchDownloadLinkProps {
  readonly canIosDownload: boolean;
  readonly requestedPlatform: EAppPlatformType | null;
  readonly getDownloadLinks: (value: DownloadLinkType[]) => void;
  readonly setFetching: (bool: boolean) => void
}

const fetchDownloadLinks = (props: FetchDownloadLinkProps) => {
  const {
    canIosDownload,
    requestedPlatform,
    getDownloadLinks,
    setFetching
  } = props;

  const {appIdAndroidGms, appIdAndroidHms, appIdIos} = AppConfigurator.getInstance().getOptions();

  const {isAndroid, isIOS, isHmsSupported, isUntypedMobileDevice, isDesktopDevice} = getUseragentInfo();

  const urls: string[] = [];

  if (isHmsSupported || requestedPlatform === EAppPlatformType.AndroidHms) {
    urls.push(getAppVersionUrl(EAppPlatformType.AndroidHms, appIdAndroidHms));
  } else if (isAndroid || requestedPlatform === EAppPlatformType.AndroidGms) {
    urls.push(getAppVersionUrl(EAppPlatformType.AndroidGms, appIdAndroidGms));
  } else if (isIOS) {
    urls.push(getAppVersionUrl(EAppPlatformType.Ios, appIdIos));
  } else if (isUntypedMobileDevice || isDesktopDevice) {
    urls.push(getAppVersionUrl(EAppPlatformType.Ios, appIdIos));
    urls.push(getAppVersionUrl(EAppPlatformType.AndroidGms, appIdAndroidGms));
    urls.push(getAppVersionUrl(EAppPlatformType.AndroidHms, appIdAndroidHms));
  }

  const requests = urls.map(url => fetch(url)).map(p => Promise.resolve(p).then(value => ({
    status: 'fulfilled',
    value: value
  }), error => ({
    status: 'rejected',
    reason: error
  })));

  setFetching(true);

  Promise.all(requests)
    .then(responses => {
      return Promise.all(responses.filter(response => response.status === 'fulfilled').map((response: any) => response.value.json()));
    })
    .then(data => {
      const downloadLinks: DownloadLinkType[] = data.map((link, index) => {
        const {downloadLink, manifestLink} = link;
        const downloadOrSourceLink = downloadLink ?? urls[index];
        if (downloadOrSourceLink.includes(EAppPlatformType.Ios)) {
          return {
            platformType: EAppPlatformType.Ios,
            url: downloadLink ? `itms-services://?action=download-manifest&url=${manifestLink}` : null,
            canDownload: canIosDownload
          };
        } else if (downloadOrSourceLink.includes(EAppPlatformType.AndroidGms)) {
          return {
            platformType: EAppPlatformType.AndroidGms,
            url: downloadLink ?? null,
            canDownload: true,
          };
        } else if (downloadOrSourceLink.includes(EAppPlatformType.AndroidHms)) {
          return {
            platformType: EAppPlatformType.AndroidHms,
            url: downloadLink ?? null,
            canDownload: true,
          };
        } else {
          throw Error(`Получен неизвестный тип приложения - ${link}`);
        }
      });

      getDownloadLinks(downloadLinks);
    })
    .catch(error => {
      console.error(error);
      const downloadLinks: DownloadLinkType[] = [
        {
          platformType: EAppPlatformType.Ios,
          url: null,
          canDownload: AppConfigurator.getInstance().hasFeature(EAppFeature.IosCanDownload)
        },
        {
          platformType: EAppPlatformType.AndroidGms,
          url: null,
          canDownload: true,
        },
        {
          platformType: EAppPlatformType.AndroidHms,
          url: null,
          canDownload: true,
        },
      ]
      getDownloadLinks(downloadLinks)
    })
    .finally(() => {
      setFetching(false);
    });
};

export default fetchDownloadLinks;
