import Link from '../../../../components/links';
import {Wrapper} from './controls';
import {AppConfigurator} from "../../../../../system/appConfigurator";
import {EAppFeature} from "../../../../../domain/model/enums";

const LandingHelp = () => {
  const isTermsOfUse = AppConfigurator.getInstance().hasFeature(EAppFeature.TermsOfUse) && !!AppConfigurator.getInstance().getOptions().support.termsOfUseUrl;
  const isPersonalDataPolicy = AppConfigurator.getInstance().hasFeature(EAppFeature.PersonalDataPolicy) && !!AppConfigurator.getInstance().getOptions().support.personalDataPolicyUrl;

  return (
    <Wrapper>
      {
        isTermsOfUse &&
        <Link color="light" target='_blank' href={AppConfigurator.getInstance().getOptions().support.termsOfUseUrl}>
          Пользовательское соглашение
        </Link>
      }
      {
        isPersonalDataPolicy &&
        <Link color="light" target='_blank'
              href={AppConfigurator.getInstance().getOptions().support.personalDataPolicyUrl}>
          Политика обработки персональных данных в ОАО «РЖД»
        </Link>
      }
    </Wrapper>
  );
};

export default LandingHelp;
